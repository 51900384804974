

.header  {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .logo{
    margin-top: 39px;
}


@media only screen and (max-width: 600px) {
    .logo{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
  }