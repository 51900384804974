body{
    box-sizing: border-box;
}


.new-line{
    border: 1.5px solid #1475A2;
    justify-content: center;
    align-items: center;
    margin-top: 45px;
    
    background-color: #1475A2;
}

.phone {
    color: #F4EC55;
}

.viber {
    color: #7F4DA0;
}

.sphone {
    color: #04C000;
}
.google {
    color:#DD4B39;
}

.internet {
    color: #36A7DB;
}

.map{
    color: #FF5C00;
}

.facebook {
    color: #3B5998;
}

.instagram {
    color: salmon;
}

.behence {
    color: black;
}

.text-box{
    margin-top: 21px;
    background: #1475A2;
    border: solid #1475A2;
    /* height: 100%; */
    width: 90%;
}

@media only screen and(min-width: 425px) and (max-width: 448px) {
    .prow {
        margin-left: -210px;
    }
}

.text-box > p{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 400;
    line-height: 25.31px;
    font-size: 18px;
    color: #fff;
    text-align: center;
}

/* .menu {
    display: grid;
} */

   /* display: flex;
    justify-content: space-around;
    align-items: center;
    width: 90%;
    margin: 0 auto;
    flex-wrap: wrap; */



    /* display: flex;
    width: 45px;
    height:45px;
    flex-basis: 33.33%;
    margin-bottom: 20px;
    margin-top: 30px;
    margin-left: 0;
    font-size: 30px; */

    .container {
        display: grid;
        grid-template-columns: auto auto auto;
        justify-content: space-evenly;
        margin: 30px 10px;
        grid-gap: 10px;
        font-size: 2.4rem;
        
      }
      

.we_provide{
    width: 4px;
    border-left: 4px solid #58C7FA;
    height: 20px;
    margin: 30px 50px;
}
.we_provide_2{
    margin-top: 26px;
    width: 4px;
    border-left: 4px solid #36A7DB;
    height: 20px;
    margin-left: 50px;
}
.we_provide_3{
    margin-top: 26px;
    width: 4px;
    border-left: 4px solid #3F99C2;
    height: 20px;
    margin-left: 50px;
}
.we_provide_4{
    margin-top: 26px;
    width: 4px;
    border-left: 4px solid #3F99C2;
    height: 20px;
    margin-left: 50px;
}
.we_provide_5{
    margin-top: 26px;
    /* width: 4px; */
    
    border-left: 4px solid #1C7EAB;
    height: 20px;
    margin-left: 50px;
}
.we_provide_6{
    margin-top: 26px;
    width: 4px;
    border-left: 4px solid #1D77A0;
    height: 20px;
    margin-left: 50px;
}
.we_provide_7{
    margin-top: 26px;
    width: 4px;
    border-left: 4px solid #13526F;
    height: 20px;
    margin-left: 50px;
}
.we_provide_8{
    margin-top: 26px;
    width: 4px;
    border-left: 4px solid #0D3243;
    height: 20px;
    margin-left: 50px;
    margin-bottom: 50px;
}


.text_2 {
    position: absolute;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    margin: 3px 14px 0px 14px;
    font-weight:500;
    text-transform: uppercase;
    font-size: 10px;
}

    @media only screen and (min-width: 448px) {
        .new-line {
            width: 65%;
        }

        /* .text-box {
            width: 109%;
        } */
    }

  @media only screen and (max-width: 1024px) {
    .new-line {
        border: 1.5px solid #1475A2;
        width: 70%;
        justify-content: center;
        align-items: center;
        margin-top: 45px;
        background-color: #1475A2;
      }
      .we_provide{
        margin-top: 26px;
        width: 4px;
        border-left: 4px solid #58C7FA;
        height: 20px;
        margin-left: 100px;
    }
    .we_provide_2{
        margin-top: 26px;
        width: 4px;
        border-left: 4px solid #36A7DB;
        height: 20px;
        margin-left: 100px;
    }
    .we_provide_3{
        margin-top: 26px;
        width: 4px;
        border-left: 4px solid #3F99C2;
        height: 20px;
        margin-left: 100px;
    }
    .we_provide_4{
        margin-top: 26px;
        width: 4px;
        border-left: 4px solid #1C7EAB;
        height: 20px;
        margin-left: 100px;
    }
    .we_provide_5{
        margin-top: 26px;
        /* width: 4px; */
        border-left: 4px solid #3F99C2;
        height: 20px;
        margin-left: 100px;
    }
    .we_provide_6{
        margin-top: 26px;
        width: 4px;
        border-left: 4px solid #1D77A0;
        height: 20px;
        margin-left: 100px;
    }
    .we_provide_7{
        margin-top: 26px;
        width: 4px;
        border-left: 4px solid #13526F;
        height: 20px;
        margin-left: 100px;
    }
    .we_provide_8{
        margin-top: 26px;
        width: 4px;
        border-left: 4px solid #0D3243;
        height: 20px;
        margin-left: 100px;
    }
}

@media only screen and (max-width: 480px) {
    .new-line {
        border: 1.5px solid #1475A2;
        width: 70%;
        justify-content: center;
        align-items: center;
        margin-left: 50px;
        margin-top: 52px;
        background-color: #1475A2;
      }
}



@media only screen and (min-width: 360px) {
    .new-line {
        margin-top:52px;
        margin-left: 50px;
    }
}

@media only screen and (min-width: 766px)  {
    .new-line {
        width: 57%;
        margin-left: 180px;
        margin-top: 20px
    }
    .prow  {
        margin: 0 80px;
      }
}   
a {
    -webkit-tap-highlight-color: transparent; /* remove tap highlight */
}
a:focus {
    outline: none; /* remove outline */
    box-shadow: none; /* remove box shadow */
}
@media only screen and (min-width:620px) and (max-width: 768px){
    .prow{
        margin: 0 100px;
    }

    .main_line{
        width: 88%;
        margin-left: 100px;
        display: block;
    }
 
}
@media only screen and (max-width: 768px) {
    .new-line{
        width: 65%;
        margin-left: 60px;
    }

    .we_provide{
        margin-top: 26px;
        width: 4px;
        height: 20px;
        margin-left: 60px
    }
    .we_provide_2{
        margin-top: 26px;
        width: 4px;
        height: 20px;
        margin-left: 60px;
    }
    .we_provide_3{
        margin-top: 26px;
        width: 4px;
        height: 20px;
        margin-left: 60px;
    }
    .we_provide_4{
        margin-top: 26px;
        width: 4px;
        height: 20px;
        margin-left: 60px;
    }
    .we_provide_5{
        margin-top: 26px;
        height: 20px;
        margin-left: 60px;
    }
    .we_provide_6{
        margin-top: 26px;
        width: 4px;
        height: 20px;
        margin-left: 60px;
    }
    .we_provide_7{
        margin-top: 26px;
        width: 4px;
        height: 20px;
        margin-left: 60px;
    }
    .we_provide_8{
        margin-top: 26px;
        width: 4px;
        height: 20px;
        margin-left: 60px;
    }
    
}


.we_provide{
    margin-top: 26px;
    width: 4px;
    border-left: 4px solid #58C7FA;
    height: 20px;
}
.we_provide_2{
    margin-top: 26px;
    width: 4px;
    border-left: 4px solid #36A7DB;
    height: 20px;
}
.we_provide_3{
    margin-top: 26px;
    width: 4px;
    border-left: 4px solid #3F99C2;
    height: 20px;
}
.we_provide_4{
    margin-top: 26px;
    width: 4px;
    border-left: 4px solid #3F99C2;
    height: 20px;
}
.we_provide_5{
    margin-top: 26px;
    border-left: 4px solid #1C7EAB;
    height: 20px;
}
.we_provide_6{
    margin-top: 26px;
    width: 4px;
    border-left: 4px solid #1D77A0;
    height: 20px;
}
.we_provide_7{
    margin-top: 26px;
    width: 4px;
    border-left: 4px solid #13526F;
    height: 20px; 
}
.we_provide_8{
    margin-top: 26px;
    width: 4px;
    border-left: 4px solid #0D3243;
    height: 20px; 
}

